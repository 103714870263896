<template>
<div>
  <!--TITULOS  -->
  <div class="air__utils__heading"> <h5> Control de Paros </h5> </div>
  <h5 class="text-secondary mt-4">Control de Paros</h5><hr>
  <!--BOTONERA  -->
  <template v-if="locked === false">
    <div class="row justify-content-end mb-4 mr-4">
      <b-button class="mr-2" pill variant="primary" @click="Play()" v-if="stopped === true">
        <b-icon icon="play-btn"/> Iniciar
      </b-button>
      <b-button pill variant="warning" @click="OpenModal()" v-else>
        <b-icon icon="stop-btn" variant="danger"/>&nbsp;Paro de trituración
      </b-button>
    </div>
  </template>
  <!--BOTONERA  -->
  <h5 class="text-secondary mt-4">Tiepos de Operación</h5><hr>
  <!-- Información general de crushingOperation -->
  <a-descriptions v-if="Object.keys(crushingOperation).length > 0" :column="{ sm: 1, md: 2 }"
                  :title="`ID Trituración: ${crushingOperation.crushings.code}`"
                  bordered size="small">
    <a-descriptions-item label="Horómetro inicial">
      <strong>{{crushingOperation.initial_horometer}}</strong>
    </a-descriptions-item>
    <a-descriptions-item label="Horómetro final">
      <strong>{{crushingOperation.final_horometer}}</strong>
    </a-descriptions-item>
    <a-descriptions-item label="Tiempo perdido">
      <strong>{{crushingOperation.wasted_time}}</strong>
    </a-descriptions-item>
    <a-descriptions-item label="Tiempo planeado">
      <strong>{{crushingOperation.planned_time}}</strong>
    </a-descriptions-item>
    <a-descriptions-item label="Tiempo total de Operación">
      <strong>{{crushingOperation.total_time}}</strong>
    </a-descriptions-item>
    <a-descriptions-item label="Estatus">
      <strong v-if="crushingOperation.stopped">
        <b-icon icon="stop-circle" variant="danger"/>
      </strong>
      <strong v-else>
        <b-icon icon="play-circle" variant="success"/>
      </strong>
    </a-descriptions-item>
  </a-descriptions>
  <!-- Información general de crushingOperation -->
  <a-row class="my-2 mt-4" align="middle" :gutter="[8,24]">
    <!--TABLA-->
      <a-table  :scroll="{x:1300}"
          :columns="crushigShutdownsTable.columns"
          :data-source="crushigShutdownsTable.data"
          :pagination="crushigShutdownsTable.pagination"
          :loading="crushigShutdownsTable.loading"
          @change="FetchCrushigShutdowns"
          :row-key="record => record.id" bordered>
        <template slot="shutdownReasons" slot-scope="shutdownReasons">{{shutdownReasons.name}}</template>
        <template slot="shutdownTypes" slot-scope="shutdownTypes">{{shutdownTypes.name}}</template>
        <template slot="time" slot-scope="crushigShutdown">
          <a-descriptions :column="{ sm: 1, md: 3 }" bordered size="small">
            <a-descriptions-item label="Inicio del paro">
              <strong>{{moment(crushigShutdown.stopped_at).format('DD/MM/YYYY HH:mm')}}</strong>
            </a-descriptions-item>
            <a-descriptions-item label="Final del paro" v-if="crushigShutdown.rebooted_at">
              <strong v-if="crushigShutdown.rebooted_at">
                {{moment(crushigShutdown.rebooted_at).format('DD/MM/YYYY HH:mm')}}
              </strong>
            </a-descriptions-item>
            <a-descriptions-item label="Duración del paro (hrs)" v-if="crushigShutdown.rebooted_at">
              <strong >
                {{ moment(crushigShutdown.rebooted_at).diff(crushigShutdown.stopped_at, 'hours') }}
              </strong>
            </a-descriptions-item>
          </a-descriptions>
        </template>
        <template slot="action" slot-scope="crushigShutdown">
          <b-button v-if="locked === false" variant="primary"
                    @click="OpenModal(crushigShutdown)"
                    pill size="sm">
            <b-icon icon="pencil"/>Editar
          </b-button>
        </template>
        <p slot="expandedRowRender" slot-scope="crushigShutdown" class="bg-white p-4 rounded">
          {{ crushigShutdown.comments }}
        </p>
      </a-table>
    <!--TABLA-->
    <!-- crushingShutdown- MODAL -->
    <b-modal title="Detalle de Trituración" ref="crushigShutdownsModal" size="lg" @hide="CloseModal" >
      <b-container fluid v-show="!spinnerLoad">
        <!--FORMULARIO-->
        <a-form-model :model="crushingShutdown.form" :rules="rules" ref="ShutdownsForm"
                      layout="vertical" :wrapper-col="{ span: 24}">
          <!--SELECT - Tipo de paro-->
          <a-form-model-item label="Tipo de paro" ref="shutdownTypes" prop="shutdownTypes" >
            <ResourceSelect v-model="crushingShutdown.form.shutdownTypes"
                            urlResource="/shutdownTypes"
                            :filters="crushingShutdownFilters"
                            placeholder="Seleccionar Tipo de paro"
            />
          </a-form-model-item>
          <!--SELECT - Tipo de paro-->
          <!--SELECT - Motivo de paro-->
          <a-form-model-item label="Motivo de paro" ref="shutdownReasons" prop="shutdownReasons" >
            <ResourceSelect v-model="crushingShutdown.form.shutdownReasons"
                            urlResource="/shutdownReasons"
                            :filters="crushingShutdownFilters"
                            placeholder="Seleccionar Motivo de paro"
            />
          </a-form-model-item>
          <!--SELECT - Motivo de paro-->
          <!--DATEPICKER - Fecha y Hora de inicio del paro-->
          <a-form-model-item  label="Fecha y Hora de inicio del paro" ref="stopped_at" prop="stopped_at" >
            <a-date-picker  v-model="crushingShutdown.form.stopped_at" style="width: 100%"
                            placeholder="Fecha y Hora de inicio del paro"
                            format="DD/MM/YYYY HH:mm" size="large" valueFormat="YYYY/MM/DD HH:mm" showTime/>
          </a-form-model-item>
          <!--DATEPICKER - Fecha y Hora de inicio del paro -->
          <!--DATEPICKER - Fecha y Hora de fin del paro-->
          <a-form-model-item  label="Fecha y Hora de fin del paro" ref="rebooted_at" prop="rebooted_at" >
            <a-date-picker  v-model="crushingShutdown.form.rebooted_at" style="width: 100%"
                            placeholder="Fecha y Hora de fin del paro"
                            format="DD/MM/YYYY HH:mm" size="large" valueFormat="YYYY/MM/DD HH:mm"
                            showTime allowClear/>
          </a-form-model-item>
          <!--DATEPICKER - Fecha y Hora de fin del paro -->
          <!--TEXT-AREA - Observaciones-->
          <a-form-model-item label="Observaciones" ref="comments"  prop="comments" >
            <a-textarea v-model="crushingShutdown.form.comments" :auto-size="{ minRows: 2}"/>
          </a-form-model-item>
          <!--TEXT-AREA -Observaciones -->
          <a-row class="my-2 mt-4" align="middle" :gutter="[8,24]" type="flex" justify="end">
            <b-button  variant="primary" @click="SubmitCrushingShutdown" pill>
              <div class="d-none d-md-block" v-b-tooltip.hover title="Guardar">
                <b-icon icon="archive"/> Guardar
              </div>
              <div class="d-md-none" v-b-tooltip.hover title="Guardar">
                <b-icon icon="archive"/>
              </div>
            </b-button>
          </a-row>
        </a-form-model>
        <!--FORMULARIO-->
      </b-container>
      <my-spinner :load="spinnerLoad"/>
      <template v-slot:modal-footer>
        <simple-modal-footer @ClickEvent="CloseModal"/>
      </template>
    </b-modal>
    <!-- crushingShutdown- MODAL -->
  </a-row>
</div>
</template>

<script>
import { fractalMixin } from '@/mixins/fractalMixin'
import moment from 'moment'
export default {
  name: 'crushingShutdowns',
  mixins: [fractalMixin],
  data() {
    return {
      locked: false,
      crushingOperationId: this.$route.params.crushingOperationId,
      crushingOperation: {},
      test: 0,
      stopped: false,
      // Table
      crushigShutdownsTable: {
        data: [],
        pagination: {
          current: 1,
          pageSize: 15,
          from: 0,
          to: 0,
          total: 0,
          lastPage: 0,
        },
        loading: false,
        columns: [
          {
            title: 'Motivo de Paro',
            dataIndex: 'shutdownReasons',
            class: 'small text-center',
            scopedSlots: { customRender: 'shutdownReasons' },
            // width: '20%',
          },
          {
            title: 'Tipo de Paro',
            dataIndex: 'shutdownTypes',
            class: 'small text-center',
            scopedSlots: { customRender: 'shutdownTypes' },
            // width: '20%',
          },
          {
            title: 'Tiempos de Paro',
            dataIndex: '',
            class: 'small text-center',
            scopedSlots: { customRender: 'time' },
            width: '50%',
          },
          {
            title: 'Acción',
            dataIndex: '',
            key: 'x',
            class: 'small text-center',
            scopedSlots: { customRender: 'action' },
            width: '10%',
          },
        ],
      },
      // form
      crushingShutdown: {
        resourceType: 'crushingShutdowns',
        form: {
          id: '',
          stopped_at: '',
          rebooted_at: '',
          comments: '',
          crushingOperations: undefined,
          shutdownReasons: undefined,
          shutdownTypes: undefined,
        },
        relationships: ['crushingOperations', 'shutdownReasons', 'shutdownTypes'],
      },
      rules: {
        stopped_at: [
          { required: true, message: 'La fecha y hora de inicio del paro es obligatoria.', trigger: 'blur' },
        ],
        shutdownReasons: [
          { required: true, message: 'El motivo del paro es obligatorio.', trigger: 'blur' },
        ],
        shutdownTypes: [
          { required: true, message: 'El tipo de paro es obligatorio.', trigger: 'blur' },
        ],
      },
      spinnerLoad: false,
      // filters
      crushingShutdownFilters: {},
    }
  },
  methods: {
    OpenModal(data = {}) {
      this.$refs.crushigShutdownsModal.show()
      if (Object.keys(data).length > 0) {
        this.crushingShutdown.form.id = data.id
        this.crushingShutdown.form.stopped_at = moment(data.stopped_at).format('YYYY/MM/DD HH:mm')
        console.log(data.rebooted_at)
        this.crushingShutdown.form.rebooted_at = data.rebooted_at ? moment(data.rebooted_at).format('YYYY/MM/DD HH:mm') : ''
        this.crushingShutdown.form.comments = data.comments
        this.crushingShutdown.form.crushingOperations = this.crushingOperationId
        this.crushingShutdown.form.shutdownReasons = data.shutdownReasons.id
        this.crushingShutdown.form.shutdownTypes = data.shutdownTypes.id
      }
    },
    CloseModal() {
      this.$refs.crushigShutdownsModal.hide()
      Object.assign(this.crushingShutdown.form, {
        id: '',
        stopped_at: '',
        rebooted_at: '',
        comments: '',
        crushingOperations: undefined,
        shutdownReasons: undefined,
        shutdownTypes: undefined,
      })
    },
    async FetchCrushingOperation() {
      const response = await this.GetResource(`crushingOperations/${this.crushingOperationId}`, {
        include: 'crushings',
      })
      if (response) {
        this.crushingOperation = response
        this.stopped = response.stopped
      }
    },
    async FetchCrushigShutdowns(pagEvt) {
      const params = {
        include: 'shutdownReasons,shutdownTypes',
        'filter[crushingOperations]': this.crushingOperationId,
      }
      await this.GetTableResource(pagEvt, 'crushingShutdowns', this.crushigShutdownsTable, params)
    },
    async SubmitCrushingShutdown() {
      this.$refs.ShutdownsForm.validate(async(valid) => {
        if (valid) {
          this.spinnerLoad = true
          this.crushingShutdown.form.crushingOperations = this.crushingOperationId
          if (this.crushingShutdown.form.id === '') {
            // Post
            await this.PostResource(this.crushingShutdown, this.SuccessCrushingShutdown, () => {
              this.spinnerLoad = false
            })
          } else {
            // Put
            await this.PutResource(this.crushingShutdown, this.SuccessCrushingShutdown, () => { this.spinnerLoad = false })
          }
        } else return false
      })
    },
    async SuccessCrushingShutdown() {
      this.CloseModal()
      await this.FetchCrushingOperation()
      await this.FetchCrushigShutdowns()
      this.spinnerLoad = false
    },
  },
  async mounted() {
    await this.FetchCrushingOperation()
    await this.FetchCrushigShutdowns()
  },
}
</script>

<style scoped>

</style>
